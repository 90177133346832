<template>
  <div>
    <no-access-info-card v-if="showNoAccessInfoCard" />
    <div v-else>
      <b-input-group class="input-group-merge mb-2 mt-2">
        <b-input-group-prepend
          class="no-border"
          is-text
        >
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          type="search"
          class="no-border"
          :placeholder="$t('Search product...')"
        />
      </b-input-group>
      <div
        v-if="products"
        class="d-flex flex-wrap"
      >
        <product-card
          v-for="product in products.data"
          :key="product.id"
          :product="product"
          :is_own="true"
        />
      </div>
      <div
        v-if="products"
        class="d-flex align-items-center justify-content-center"
      >
        <b-pagination
          v-if="products.meta.pagination.total > products.meta.pagination.per_page"
          v-model="pageNumber"
          :per-page="products.meta.pagination.per_page"
          :total-rows="products.meta.pagination.total"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <div
        v-if="!products || !products.data.length"
        class="d-flex align-items-center justify-content-center"
      >
        <b-img
          src="@/assets/images/pages/course/empty-list.svg"
          alt="Buypass"
          class="d-inline-block mt-5"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BImg,
} from 'bootstrap-vue'

import ProductCard from '@/components/marketplace/ProductCard.vue'
import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BPagination,
    BImg,

    ProductCard,
    NoAccessInfoCard,
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
    products() {
      return this.$store.getters['myProducts/products']
    },
    pageNumber: {
      set(pageNumber) {
        this.$store.commit('myProducts/setPageNumber', pageNumber)
        this.$store.dispatch('myProducts/fetchProducts')
      },
      get() {
        return this.$store.getters['myProducts/pageNumber']
      },
    },
    user() {
      return this.$store.getters['auth/user']
    },
    representACompany() {
      return 'id' in this.user.active_business_profile
    },
    showNoAccessInfoCard() {
      if (!this.isAuthenticated) return false
      if (this.representACompany) return false
      if (this.user.user_info.approved_hrp !== 2) return true
      return false
    },
  },
  created() {
    if (!this.showNoAccessInfoCard) this.$store.dispatch('myProducts/fetchProducts')
  },
}
</script>
